<template>
  <div id="shipment-detail" class="m-3">
    <div v-if="isLoading" class="loading loading-big" style="margin-top: 25%"></div>
    <div v-else class="m-0 p-0">
    <div class="d-flex p-3 header-wrapper">
      <!-- 1st Row -->
      <p class="my-3 mx-3">
        <label class="mr-3">Sales Shipment No:</label>
        <span class="text-black">{{ shipmentDetail.salesShipmentNo }}</span>
      </p>
    </div>
    <div class="my-5 body-wrapper">
      <!-- 2nd Row -->
      <div class="d-flex col-12 p-0 justify-content-between">
        <div class="">
          <label class="m-0 mr-5 pt-2">Shipment Status:</label>
          <label id="shipment-status" :style="'color:'+statusObj.color">{{ statusObj.text }}</label>
        </div>
        <div class="">
          <label class="mr-3 pt-2">Posting Date:</label>
          <span class="text-black">{{ shipmentDetail.postingDate ? shipmentDetail.postingDate : 'No date available' }}</span>
        </div>
      </div>
      
      <!-- 3rd Row -->
      <div class="d-flex col-12 p-0 mt-5 justify-content-between">
        <label class="col-3 p-0">
          Sales Shipment No
        </label>
        <label class="col-3 p-0">
          Sell to Customer No
        </label>
        <label class="col-3 p-0">
          Sales Person Code
        </label>
      </div>
      <div class="d-flex col-12 p-0 justify-content-between">
        <input v-model="shipmentDetail.salesShipmentNo" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.sellToCustomerNo" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.salespersonCode" type="text" class="form-control col-3 m-0" disabled>
      </div>
      <hr class="my-5">
      <!-- 4th Row -->
      <div class="d-flex col-12 p-0 justify-content-between">
        <label class="col-3 p-0">
          Contact Person
        </label>
        <label class="col-3 p-0">
          Sell to Customer Name
        </label>
        <label class="col-3 p-0">
          Ship to Customer Name
        </label>
      </div>
      <div class="d-flex col-12 p-0 justify-content-between">
        <input v-model="shipmentDetail.contactPerson" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.sellToCustomerName" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.shipToCustomerName" type="text" class="form-control col-3 m-0" disabled>
      </div>
      <hr class="my-5">
      <!-- 5th Row -->
      <div class="d-flex col-12 p-0 justify-content-between">
        <label class="col-3 p-0">
          Ship To Code
        </label>
        <label class="col-3 p-0">
          Order No
        </label>
        <label class="col-3 p-0">
          <!-- Purposely left empty -->
        </label>
      </div>
      <div class="d-flex col-12 p-0 justify-content-between">
        <input v-model="shipmentDetail.shipToCode" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.orderNo" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <div class="col-3 m-0"><!-- Purposely left empty --></div>
      </div>
      <!-- 6th Row -->
      <div class="d-flex col-12 p-0 mt-5">
        <label class="col-12 p-0">
          Ship to Address 1
        </label>
      </div>
      <div class="d-flex col-12 p-0">
        <input v-model="shipmentDetail.shipToAddress1" type="text" class="form-control col-12 m-0" disabled>
      </div>
      <!-- 7th Row -->
      <div class="d-flex col-12 p-0 mt-5">
        <label class="col-12 p-0">
          Ship to Address 2
        </label>
      </div>
      <div class="d-flex col-12 p-0">
        <input v-model="shipmentDetail.shipToAddress2" type="text" class="form-control col-12 m-0" disabled>
      </div>
      <hr class="my-5">
      <!-- 8th Row -->
      <div class="d-flex col-12 p-0 justify-content-between">
        <label class="col-3 p-0">
          Contact No
        </label>
        <label class="col-3 p-0">
          Vehicle
        </label>
        <label class="col-3 p-0">
          Driver
        </label>
      </div>
      <div class="d-flex col-12 p-0 justify-content-between">
        <input v-model="shipmentDetail.contactNumber" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.vehicle" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.driver" type="text" class="form-control col-3 m-0" disabled>
      </div>
      <hr class="my-5">
      <!-- 9th Row -->
      <div class="d-flex col-12 p-0 justify-content-between">
        <label class="col-3 p-0">
          Depart from Plant - Geo Location
        </label>
        <label class="col-3 p-0">
          Depart from Plant - Date
        </label>
        <label class="col-3 p-0">
          Depart from Plant - Time
        </label>
      </div>
      <div class="d-flex col-12 p-0 justify-content-between">
        <div class="m-0 p-0 mr-5 col-3">
          <div class="custom-icon location-icon icon-float-right"></div>
          <input v-model="shipmentDetail.departLocation" type="text" class="form-control col-12" disabled>
        </div>
        <input v-model="shipmentDetail.departDate" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.departTime" type="text" class="form-control col-3 m-0" disabled>
      </div>
      <!-- 10th Row -->
      <div class="d-flex col-12 p-0 mt-5 justify-content-between">
        <label class="col-3 p-0">
          Arrive Customer Site - Geo Location
        </label>
        <label class="col-3 p-0">
          Arrive Customer Site - Date
        </label>
        <label class="col-3 p-0">
          Arrive Customer Site - Time
        </label>
      </div>
      <div class="d-flex col-12 p-0 justify-content-between">
        <div class="m-0 p-0 mr-5 col-3">
          <div class="custom-icon location-icon icon-float-right"></div>
          <input v-model="shipmentDetail.arriveLocation" type="text" class="form-control col-12" disabled>
        </div>
        <input v-model="shipmentDetail.arriveDate" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.arriveTime" type="text" class="form-control col-3 m-0" disabled>
      </div>
      <!-- 11th Row -->
      <div class="d-flex col-12 p-0 mt-5 justify-content-between">
        <label class="col-3 p-0">
          Complete Geo Location
        </label>
        <label class="col-3 p-0">
          Complete Date
        </label>
        <label class="col-3 p-0">
          Complete Time
        </label>
      </div>
      <div class="d-flex col-12 p-0 justify-content-between">
        <div class="m-0 p-0 mr-5 col-3">
          <div class="custom-icon location-icon icon-float-right"></div>
          <input v-model="shipmentDetail.completeLocation" type="text" class="form-control col-12" disabled>
        </div>
        <input v-model="shipmentDetail.completeDate" type="text" class="form-control col-3 m-0 mr-5" disabled>
        <input v-model="shipmentDetail.completeTime" type="text" class="form-control col-3 m-0" disabled>
      </div>
      <hr class="my-5">
      <!-- 12th Row -->
      <div class="d-flex col-12 p-0 mt-5 justify-content-between">
        <label class="col-3 p-0">
          Customer Weight
        </label>
        <label class="col-2 p-0">
          Customer Weight Ticket Picture
        </label>
        <label class="col-2 p-0">
          Customer Signed DO Picture
        </label>
        <label class="col-2 p-0">
          Reka/Aquo Flo DO Picture
        </label>
      </div>
      <div class="d-flex col-12 p-0 justify-content-between">
        <div class="col-3 m-0 p-0">
          <input v-model="shipmentDetail.customerWeight" type="text" class="form-control" disabled>
        </div>
        <div class="col-2 m-0 p-0 d-inline-flex">
          <div class="image-cover d-flex align-content-center justify-content-center" 
          @click="viewPicture(shipmentDetail.pictureUrl1)"
          >
            <img width="100" v-if="shipmentDetail.pictureUrl1" :src="shipmentDetail.pictureUrl1">
            <p v-if="shipmentDetail.pictureUrl1" class="m-auto">
              View
            </p>
            <span v-else class="m-3"> There is no image available</span>
          </div>
        </div>
        <div class="col-2 m-0 p-0 d-inline-flex">
          <div class="image-cover d-flex align-content-center justify-content-center" 
          @click="viewPicture(shipmentDetail.pictureUrl2)"
          >
            <img width="100" v-if="shipmentDetail.pictureUrl2" :src="shipmentDetail.pictureUrl2">
            <p v-if="shipmentDetail.pictureUrl2" class="m-auto">
              View
            </p>
            <span v-else class="m-3"> There is no image available</span>
          </div>
        </div>
        <div class="col-2 m-0 p-0 d-inline-flex">
          <div class="image-cover d-flex align-content-center justify-content-center" 
          @click="viewPicture(shipmentDetail.pictureUrl3)"
          >
            <img width="100" v-if="shipmentDetail.pictureUrl3" :src="shipmentDetail.pictureUrl3">
            <p v-if="shipmentDetail.pictureUrl3" class="m-auto">
              View
            </p>
            <span v-else class="m-3"> There is no image available</span>
          </div>
        </div>
      </div>
      <hr class="my-5">
      <!-- 12th Last row (table) -->
      <VueBootstrap4Table 
        :classes="classes"
        :rows="shipmentDetail.lineDetails"
        :total-rows="shipmentDetail.lineDetails.length"
        :columns="columns" 
        :config="config"
      >
        <template slot="empty-results">
          <div
            v-if="!isLoading"
            class="bold-text"
          >No records found</div>
          <div
            v-if="isLoading"
            class="row justify-content-center mt-3"
          ><div class="loading"></div></div>
        </template>
        <template slot="custom-desc" slot-scope="props">
          <div>{{props.row.description | truncateForLength(40)}}</div>
        </template>
      </VueBootstrap4Table>

    <!-- Back to Top -->
    <a id="back-to-top" href="#header-top" class="">
      <div class="d-flex justify-content-center">
        <i class="arrow my-auto up"></i>
      </div>
      <p class="text-center">Back To Top</p>
    </a>

    <div style="padding-bottom: 20rem"></div>
    </div>
    <div class="page-footer">
      <div class="row justify-content-center">
        <router-link to="/shipment-management" class="btn btn-back justify-content-center mr-5 px-4">Back to Shipment Listing</router-link>
        <b-button v-if="canCancel" 
          @click="handleCancel(false)" class="ml-3">Cancel this shipment</b-button>
      </div>
    </div>

    <!-- Confirm modal -->
    <b-modal ref="confirm-modal" hide-footer>
      <div class="mx-4">
        <h3>Are you sure you want to cancel this shipment</h3><br/>
        <p>Sales Shipment No = <b>{{this.shipmentDetail.salesShipmentNo}}</b></p>
        <div class="float-right mb-4 w-25">
          <b-button block @click="handleCancel(true)">Yes</b-button>
        </div>
      </div>
    </b-modal>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { truncateForLength } from "../../utils/filters"
import { convertDateFormat, addCommasToNumbers } from "../../utils/helpers"
import { EventBus } from "../../utils/EventBus";
import * as shipmentActions from "../../store/modules/shipment/actions"
import * as uiActions from "../../store/modules/_shared/ui/actions"

//Dont import globally since only used by this page
import 'viewerjs/dist/viewer.css'
import { api as viewerApi } from "v-viewer"

export default {
  name: "ShipmentManagementDetail",
  components: {
    VueBootstrap4Table: () => import("@/components/ui/VueBootstrap4Table/VueBootstrap4Table"),
  },
  filters: {
    truncateForLength,
  },
  data() {
    return {
      isLoading: false,
      shipmentId: null,
      currentUser: this.$store.getters.getUser,
      canCancel: false,
      availableShipmentStatus: [],
      statusObj: {},
      shipmentDetail: {
        salesShipmentNo: null,
        sellToCustomerNo: null,
        salespersonCode: null,
        contactPerson: null,
        sellToCustomerName: null,
        shipToCustomerName: null,
        orderNo: null,
        shipToAddress1: null,
        shipToAddress2: null,
        contactNumber: null,
        departLocation: null,
        departDate: null,
        departTime: null,
        arriveLocation: null,
        arriveDate: null,
        arriveTime: null,
        completeLocation: null,
        completeDate: null,
        completeTime: null,
        status: null,
        shipToCode: null,
        pictureUrl1: null,
        pictureUrl2: null,
        pictureUrl3: null,
        customerWeight: null,
        vehicle: null,
        driver: null,
        lineDetails: []
      },
      // Table
      columns: [
        {
          label: "No",
          name: "lineNo",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
        },
        {
          label: "Description",
          name: "description",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
          slot_name: "custom-desc",
        },
        {
          label: "Unit of Measure Code",
          name: "unitOfMeasureCode",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
        },
        {
          label: "Quantity",
          name: "quantity",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
        },
        {
          label: "Complete Date",
          name: "completeDate",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
        },
        {
          label: "Complete Time",
          name: "completeTime",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
        },
        {
          label: "Customer Weight",
          name: "customerWeight",
          column_text_alignment: "text-left",
          row_text_alignment: "text-left",
        },
      ],
      config: {
        emitQuery: false,
        checkbox_rows: false,
        rows_selectable: false,
        server_mode: true,
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        preservePageOnDataChange: false,
        pagination_info: false,
        pagination: false,
        multi_column_sort: false,
        global_search: {
          visibility: false,
        },
        highlight_row_hover_color: "grey"
      },
      classes: {
        table: {
          "spci-table": true,
        }
      },
    }
  },
  mounted() {
    this.shipmentId = this.$route.params.shipmentId
    this.availableShipmentStatus = this.$store.getters.getAvailableShipmentStatus
    this.getDetail(this.shipmentId)
  },
  watch: {},
  methods: {
    getDetail(id) {
      this.isLoading = true
      this.$store.dispatch(shipmentActions.SHIPMENT_REQUEST, {shipmentId: id}).then(resp => {
        this.shipmentDetail = cloneDeep(resp.response)
        this.parseDetail()
        this.setCanCancel()
        EventBus.$emit('passSubtitles', [this.shipmentDetail.salesShipmentNo])
      }).finally(() => this.isLoading = false)
    },
    parseDetail() {
      this.statusObj = this.availableShipmentStatus.find(el => el.value == this.shipmentDetail.status)
      let lines = this.shipmentDetail.lineDetails

      if (this.shipmentDetail.customerWeight) {
        this.shipmentDetail.customerWeight = addCommasToNumbers(this.shipmentDetail.customerWeight)
      }

      // Parse all GeoLoc, date and time in depart, arrive and complete
      if (this.shipmentDetail.departGeoLocLat) {
        this.shipmentDetail.departLocation = "" 
          + this.shipmentDetail.departGeoLocLat + ", " 
            + this.shipmentDetail.departGeoLocLng
      }
      if (this.shipmentDetail.departTimestamp) {
        this.shipmentDetail.departDate = convertDateFormat(this.shipmentDetail.departTimestamp, "DD/MM/YYYY")
        this.shipmentDetail.departTime = convertDateFormat(this.shipmentDetail.departTimestamp, "hh:mm A")
      }
      if (this.shipmentDetail.arriveGeoLocLat) {
        this.shipmentDetail.arriveLocation = "" 
          + this.shipmentDetail.arriveGeoLocLat + ", " 
            + this.shipmentDetail.arriveGeoLocLng
      }
      if (this.shipmentDetail.arriveTimestamp) {
        this.shipmentDetail.arriveDate = convertDateFormat(this.shipmentDetail.arriveTimestamp, "DD/MM/YYYY")
        this.shipmentDetail.arriveTime = convertDateFormat(this.shipmentDetail.arriveTimestamp, "hh:mm A")
      }
      if (this.shipmentDetail.completeGeoLocLat) {
        this.shipmentDetail.completeLocation = "" 
          + this.shipmentDetail.completeGeoLocLat + ", " 
            + this.shipmentDetail.completeGeoLocLng
      }
      if (this.shipmentDetail.completeTimestamp) {
        this.shipmentDetail.completeDate = convertDateFormat(this.shipmentDetail.completeTimestamp, "DD/MM/YYYY")
        this.shipmentDetail.completeTime = convertDateFormat(this.shipmentDetail.completeTimestamp, "hh:mm A")
        // each lineDetail will have same complete timestamp as general
        lines.map(el => {
          el.completeDate = this.shipmentDetail.completeDate
          el.completeTime = this.shipmentDetail.completeTime
        })
      }

      // each lineDetail
      lines.map(el => {
        if(el.customerWeight) el.customerWeight = addCommasToNumbers(el.customerWeight)
        return el
      })
    },
    setCanCancel() {
      let isShipmentIsCancelOrComplete = 
        this.statusObj.value == 'SHIPMENT_CANCEL' 
        || this.statusObj.value == 'SHIPMENT_COMPLETE'
      
      this.canCancel = !isShipmentIsCancelOrComplete
        && (this.currentUser.isAdmin || this.currentUser.isManager)
    },
    viewPicture(url) {
      if (url !== "") {
        // Current only one url, if there are more, please refer to the docs -> https://www.npmjs.com/package/v-viewer
        viewerApi({
          images: [url]
        })
      }
    },
    handleCancel(confirm) {
      this.$refs["confirm-modal"].toggle("#toggle-btn");
      if (!confirm) return
      let postData = {
        shipmentId: +this.shipmentId,
        userId: this.currentUser.userId,
      }
      this.$store.dispatch(shipmentActions.SHIPMENT_CANCEL_REQUEST, postData).then(res => {
        if (res.status != "ok") throw(res)
        this.$store.dispatch(uiActions.NOTIFICATION_SUCCESS, {
          message: res.response
        })
        this.$router.push({path: "/shipment-management"})
      }).catch(err => {console.log(err)})
    }
  }
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  background-color: white;
  text-align: center;
  border-radius: 10px;
  p {
    height: 1rem;
    line-height: 1rem;
  }
}

input {
  font-size: 1.6rem;
}

#shipment-status {
  background-color: white;
  font-size: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid black;
  border-radius: 2rem;
}

.custom-select {
  height: 100%;
  border-radius: 10px;
  border: none;
  color: inherit;
}

.btn-back {
  color: #545557;
  border : 1px solid #C2C5D3;
}

.image-cover {
  border-radius: 10px;
  width: 100px;
  height: 100px;
  background-color: #000000;
  opacity: 0.5;
  cursor: pointer;
  img {
    border-radius: 10px;
    object-fit: cover;
  }
  p {
    position: absolute;
    top: 40px;
    left: 35px;
    color: white;
    opacity: 1;
  }
}

#back-to-top {
  position: absolute;
  bottom: 13rem;
  right: 50px;
  width: 100px;
  height: 80px;
  cursor: pointer;
  div { //Circle
    margin: auto;
    border-radius: 50%;
    background-color: #FFD7D9;
    opacity: 52%;
    width: 50px;
    height: 50px;
  }
  .arrow {
    border-color: #FF0A16;
    padding: 8px;
    margin-bottom: 12px !important;
  }
  p {
    color: #787A7B;
    opacity: 50%;
  }
}

</style>